<template>
    <div class="absolute top-0 left-0 w-full h-[90%] z-1" style="background: rgb(123, 42, 255); background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%)"></div>
    <div id="contact-us" ref="contact" class="my-[5%] relative">
        <div class="absolute top-10 left-20 z-[12] w-[fit-content] h-[fit-content] opacity-70">
            <svg width="88" height="100" viewBox="0 0 88 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M-0.000999244 11.5433C5.76175 7.26965 12.3098 4.17288 19.2694 2.42984C26.2289 0.686792 33.4637 0.331599 40.5605 1.38454C47.6573 2.43748 54.4772 4.87793 60.6309 8.56655C66.7846 12.2552 72.1514 17.1197 76.4251 22.8825C80.6988 28.6452 83.7955 35.1933 85.5386 42.1529C87.2816 49.1124 87.6368 56.3471 86.5839 63.444C85.5309 70.5408 83.0905 77.3607 79.4019 83.5144C75.7132 89.668 70.8487 95.0349 65.0859 99.3086L32.5425 55.426L-0.000999244 11.5433Z"
                    fill="url(#paint0_linear_5813_24077)"
                />
                <defs>
                    <linearGradient id="paint0_linear_5813_24077" x1="38.4561" y1="74.4076" x2="-12.0881" y2="-40.8466" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#85D5FF" />
                        <stop offset="1" stop-color="#B8FFF1" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        <div class="absolute top-0 left-[40%] z-[12] w-[fit-content] h-[fit-content] opacity-70">
            <svg width="109" height="112" viewBox="0 0 109 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M50.3086 0.26797C40.2725 1.23687 30.6882 4.91433 22.5799 10.9074C14.4715 16.9005 8.14386 24.984 4.27298 34.2941C0.402087 43.6042 -0.866543 53.7911 0.602642 63.7662C2.07183 73.7413 6.22362 83.1299 12.6144 90.9286C19.0051 98.7273 27.3947 104.643 36.8866 108.044C46.3785 111.445 56.6161 112.203 66.5053 110.237C76.3946 108.271 85.564 103.655 93.0336 96.883C100.503 90.1105 105.992 81.4358 108.915 71.7859L85.831 64.7955C84.1754 70.2629 81.0653 75.1778 76.8332 79.0149C72.6011 82.852 67.406 85.4672 61.8029 86.581C56.1999 87.6948 50.3996 87.2653 45.0217 85.3385C39.6438 83.4116 34.8905 80.0599 31.2697 75.6413C27.6488 71.2227 25.2965 65.9034 24.4641 60.2518C23.6317 54.6001 24.3505 48.8285 26.5437 43.5536C28.7368 38.2787 32.3219 33.6988 36.9159 30.3033C41.5099 26.9078 46.9401 24.8242 52.6263 24.2753L50.3086 0.26797Z"
                    fill="url(#paint0_linear_5813_24078)"
                />
                <defs>
                    <linearGradient id="paint0_linear_5813_24078" x1="82.6298" y1="127.291" x2="-19.3883" y2="49.6756" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#75D0FF" />
                        <stop offset="1" stop-color="#B8FFF1" />
                    </linearGradient>
                </defs>
            </svg>
        </div>

        <div class="contact-us mt-[1%] relative z-3">
            <div class="left-side z-3" id="contact">
                <h1 class="text-[#fff]">
                    {{ $t("Any questions?") }}<br />
                    {{ $t("Write or call us. We will write back within 12h") }}
                </h1>
                <p class="text-[#fff]">{{ $t("contactText") }}</p>
                <div class="w-[75%] mx-auto shadow-card rounded-[20px] bg-[#fff] p-5">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center gap-2">
                            <svg width="53" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="83" height="83" rx="20" fill="#9570FF" />
                                <path d="M18 36L40.5 44.6207L63 36V51C63 56.5228 58.5228 61 53 61H28C22.4772 61 18 56.5228 18 51V36Z" fill="#BFA9FF" />
                                <path d="M18 33C18 27.4772 22.4772 23 28 23H53C58.5228 23 63 27.4772 63 33V33.3636L40.5 42L18 33.3636V33Z" fill="white" />
                            </svg>
                            <span>Mail Us</span>
                        </div>
                        <p>contact@go-platform.dz</p>
                    </div>
                    <hr />
                    <div class="flex justify-between items-center">
                        <div class="flex items-center gap-2">
                            <svg width="53" height="84" viewBox="0 0 83 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.34375" width="83" height="83" rx="20" fill="#F563D3" />
                                <path
                                    d="M21.0699 31.3596C21.0839 31.4382 21.0952 31.5168 21.0999 31.596C21.1592 32.6132 21.9282 40.7292 31.5962 50.611C31.5962 50.611 39.9477 59.1219 48.7374 60.9114C48.7754 60.9192 48.8128 60.9277 48.8508 60.9382C49.383 61.083 53.9075 62.2266 56.2398 59.8876L59.5626 56.6245C59.5926 56.595 59.6239 56.5662 59.6559 56.5387C59.9874 56.2537 62.295 54.1217 59.9867 51.8548C57.6504 49.5604 54.7052 46.7629 54.3717 46.4459C54.3557 46.4309 54.3404 46.4158 54.3251 46.4001C54.1443 46.2154 52.4276 44.5543 50.4168 46.0719C50.3587 46.1158 50.304 46.1649 50.252 46.216L46.9987 49.4196C46.2697 50.1106 45.1119 50.1014 44.3936 49.3993L33.2789 38.5258C32.5326 37.7955 32.552 36.6028 33.3216 35.896L35.9227 33.5092C35.9227 33.5092 38.7726 30.9181 35.712 28.1193L31.1687 23.6576C31.1407 23.63 31.114 23.6019 31.0874 23.5724C30.7939 23.2475 28.6163 21.0232 26.0613 23.499C25.9832 23.5744 25.8985 23.6425 25.8085 23.7028C24.9415 24.287 20.3849 27.5515 21.0699 31.3596Z"
                                    fill="white"
                                />
                                <path
                                    d="M51.6918 39.3084C52.3955 39.3081 52.9749 38.7345 52.8752 38.0379C52.7522 37.1778 52.5049 36.3378 52.1392 35.5446C51.5953 34.3646 50.8022 33.3164 49.8146 32.472C48.827 31.6276 47.6683 31.007 46.4181 30.653C45.5778 30.415 44.7096 30.3013 43.8407 30.3133C43.1371 30.3231 42.6606 30.9846 42.7695 31.6798C42.8785 32.375 43.5338 32.8369 44.2369 32.8664C44.7387 32.8875 45.2378 32.9672 45.7238 33.1048C46.62 33.3586 47.4507 33.8035 48.1586 34.4088C48.8666 35.0141 49.4351 35.7655 49.825 36.6114C50.0365 37.0702 50.1928 37.5509 50.2916 38.0433C50.43 38.7332 50.9881 39.3088 51.6918 39.3084Z"
                                    fill="#F9A1E5"
                                />
                                <path
                                    d="M56.9551 39.4529C57.8027 39.5193 58.553 38.8844 58.5213 38.0348C58.4636 36.4895 58.1377 34.962 57.5541 33.5216C56.7772 31.6042 55.5652 29.8938 54.0137 28.5254C52.4623 27.1569 50.6139 26.168 48.6146 25.6366C47.1125 25.2375 45.5563 25.1048 44.0159 25.2406C43.169 25.3152 42.6327 26.1388 42.8044 26.9715C42.9761 27.8042 43.7916 28.3277 44.6405 28.2824C45.7092 28.2254 46.7834 28.3357 47.8238 28.6122C49.3618 29.0209 50.7837 29.7817 51.9772 30.8343C53.1706 31.887 54.103 33.2028 54.7006 34.6777C55.1048 35.6754 55.3484 36.7275 55.4252 37.7949C55.4863 38.6429 56.1075 39.3866 56.9551 39.4529Z"
                                    fill="#F9A1E5"
                                />
                            </svg>
                            <span>Call Us</span>
                        </div>
                        <p>+1 (413) 772 9811</p>
                    </div>
                </div>
                <div class="contact-container">
                    <!-- <div class="number-container" @click="copyNumber">
                        <font-awesome-icon class="contact-icon" icon="fa-solid fa-phone" />
                        +1 (413bg-red-500) 772 9811
                    </div>
                    <div class="email-container">
                        <font-awesome-icon class="contact-icon" icon="fa-regular fa-envelope" />
                        <a href="mailto:contact@go-platform.dz">contact@go-platform.dz</a>
                    </div> -->
                </div>
            </div>
            <div class="right-side bg-[#fff] shadow-card rounded-[20px] py-[4%] px-[4%] z-3">
                <div class="w-full flex flex-col text-[14px] items-start relative">
                    <label for="name" class="my-2 after:content-['*'] after:ml-0.5 after:text-red-500">Full name</label>
                    <input v-model="emailData.name" class="shadow-card" name="name" type="text" :placeholder="$t('John David')" />
                    <span v-if="requiredFields.name" class="err_msg">{{ requiredFields.name }}</span>
                </div>
                <div class="w-full flex flex-col text-[14px] items-start relative">
                    <label for="email" class="my-2 after:content-['*'] after:ml-0.5 after:text-red-500">Email</label>
                    <input v-model="emailData.email" class="shadow-card" name="email" type="email" :placeholder="$t('example@youremail.com')" />
                    <span v-if="requiredFields.email" class="err_msg">{{ requiredFields.email }}</span>
                </div>
                <div class="w-full flex flex-col text-[14px] items-start relative">
                    <label for="company" class="my-2 after:content-['*'] after:ml-0.5 after:text-red-500">Company</label>
                    <input v-model="emailData.company" class="shadow-card" name="company" type="text" :placeholder="$t('Company name')" />
                    <span v-if="requiredFields.company" class="err_msg">{{ requiredFields.company }}</span>
                </div>
                <div class="w-full flex flex-col text-[14px] items-start relative">
                    <label for="subject" class="my-2 after:content-['*'] after:ml-0.5 after:text-red-500">Subject</label>
                    <input v-model="emailData.subject" class="shadow-card" name="subject" type="text" :placeholder="$t('How can we help')" />
                    <span v-if="requiredFields.subject" class="err_msg">{{ requiredFields.subject }}</span>
                </div>
                <div class="w-full flex flex-col text-[14px] items-start relative">
                    <label for="message" class="my-2 after:content-['*'] after:ml-0.5 after:text-red-500">Message</label>
                    <textarea
                        class="shadow-card"
                        v-model="emailData.message"
                        style="resize: none"
                        rows="5"
                        id="messageTextarea"
                        name="message"
                        placeholder="Hello there, I would like to talk about..."
                    ></textarea>
                    <span v-if="requiredFields.message" class="err_msg">{{ requiredFields.message }}</span>
                </div>

                <button
                    class="btn-contact"
                    @click="sendEmail"
                    v-if="!isLoading"
                    style="background: rgb(123, 42, 255); background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%)"
                    type="submit"
                >
                    {{ $t("Send") }}
                    <font-awesome-icon class="arrow-right" icon="fa-solid fa-arrow-right" />
                </button>
                <button class="btn-contact btn-loading" style="background: rgb(123, 42, 255); background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%)" v-else>
                    <LoaderComponent />
                </button>
                <p class="text-[14px] text-center text-green-500">{{ successMsg }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import axios from "axios";
export default {
    name: "contactUs",
    components: {
        LoaderComponent,
    },
    data() {
        return {
            emailData: {
                name: "",
                email: "",
                company: "",
                subject: "",
                message: "",
            },
            requiredFields: {
                name: "",
                email: "",
                company: "",
                subject: "",
                message: "",
            },
            successMsg: "",
            isLoading: false,
        };
    },
    methods: {
        validateForm() {
            let valid = true;

            if (this.emailData.name === "") {
                this.requiredFields.name = "Name is required";
                valid = false;
            }
            const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format

            if (this.emailData.email === "") {
                this.requiredFields.email = "Email is required";
                valid = false;
            } else if (!emailRegex.test(this.emailData.email)) {
                this.requiredFields.email = "Email format not valid.";
                valid = false;
            }
            if (this.emailData.company === "") {
                this.requiredFields.company = "Company is required";
                valid = false;
            }
            if (this.emailData.subject === "") {
                this.requiredFields.subject = "Subject is required";
                valid = false;
            }
            if (this.emailData.message === "") {
                this.requiredFields.message = "Message is required";
                valid = false;
            }
            return valid;
        },
        sendEmail() {
            let valid = this.validateForm();
            if (valid) {
                this.isLoading = true;
                axios
                    .post("https://server.go-platform.com/contact-us/", this.emailData)
                    .then((res) => {
                        this.isLoading = false;
                        this.emailData = {
                            name: "",
                            email: "",
                            company: "",
                            subject: "",
                            message: "",
                        };
                        console.log(res);
                        this.successMsg = res.data.message;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // background: #1642f8;
    padding: 10rem;
    font-family: Roboto;
    gap: 8%;
    // margin-bottom: -5%;
    // background: #f4faff;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1rem 0;

        .left-side {
            width: 80%;
            padding: 1rem 2rem;

            h1 {
                font-size: 40px;
            }

            .contact-container {
                flex-direction: column-reverse;
            }
        }

        .right-side {
            width: fit-content;
            // padding: 1rem 2rem;
        }

        .right-side {
            input,
            textarea,
            select,
            .select-container {
                width: 100%;
            }
        }
    }

    input {
        width: 100%;
        padding: 0.8rem;
        border-radius: 10px;
        //  outline-color: #2196f3;
        background-color: #fff;
        outline: none;
    }

    textarea {
        width: 100%;
        padding: 1rem;
        border-radius: 10px;
        background-color: #fff;
        // outline-color: #2196f3;

        outline: none;
    }

    select {
        width: 100%;

        cursor: pointer;

        outline: none;
    }

    /*input:focus,
    textarea:focus,
    select:focus {

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
    }*/

    .arrow-right {
        width: 13px;
        margin-left: 5px;
    }
}

.btn-contact {
    width: 100%;
    height: 60px;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-contact:hover {
    opacity: 0.85;
}

.select-container {
    width: 55%;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
}

.btn-loading {
    opacity: 0.85;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-icon {
    margin-right: 5px;
}

.contact-us p {
    text-align: start;
    font-family: Roboto;
    font-style: normal;
    // color: #263238;
}

.contact-us h1 {
    text-align: start;
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    // color: #263238 !important;
}

.contact-us span {
    text-align: start;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    // font-size: 27px;
    // color: #2196f3;
}

.left-side {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    // color: #2196f3;
}

.right-side {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 550;
    color: #2196f3;
}

.err_msg {
    color: #ff6969;
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    top: 100%;
}
</style>
