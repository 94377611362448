<template>
    <div
        style="margin-top: 30px; height: 500px; width: 90%"
        class="bg-white flex flex-col rounded-md shadow-[0_0_6px_0_rgba(0,0,0,20%)] pt-0 pb-10 mx-auto hover:scale-105 duration-500 ease-out overflow-hidden relative"
    >
        <h2 style="font-size: 25px" class="w-full px-10 py-4 text-slate-700 text-xl font-semibold px-8 text-left">
            {{ name }}
        </h2>
        <div style="color: #5a5e60; font-weight: bold; padding: 0px 32px; text-align: left; padding-bottom: 24px">
            <img src="../assets/GO_logo.png" style="width: 24px; display: inline-block; padding-right: 4px" /> Go platform
        </div>
        <hr style="height: 1px; width: 100%" />
        <p class="w-full text-left text-slate-700 text-sm font-light px-8 py-6" style="color: #6b6f71; font-size: 17px">{{ this.preview ? truncatePreview : "" }}</p>
        <div class="flex flex-wrap gap-2" style="margin-bottom: 20px; padding: 0px 20px">
            <span
                v-for="(recommendation, index) in getThreeRec(recommendations)"
                :key="index"
                href="/"
                class=""
                style="
                    background-color: rgb(236, 238, 238);
                    color: rgb(44, 47, 48);
                    border-radius: 20px;
                    padding: 10px 17px;
                    font-size: 12px;
                    width: fit-content;
                    height: fit-content;
                    font-weight: bold;
                "
            >
                {{ truncateRecommendation(recommendation) }}</span
            >
        </div>
        <div class="absolute top-[410px] left-0 w-full h-[60px]">
            <hr style="height: 1px; width: 100%; margin-bottom: 20px" />
            <div class="w-full mt-auto flex flex-row justify-end items-center px-8">
                <router-link
                    to="/register"
                    class="text-base text-[14px] font-[700] mr-4 py-3 px-4 border border-slate-300 text-white bg-NeonBlue whitespace-nowrap"
                    style="background-color: #0c58b6; color: white"
                    >Use assessment</router-link
                >

                <span class="text-base w-28 py-3 border border-slate-300 text-slate-700 flex flex-row gap-1 justify-center items-center cursor-pointer" @click="navigateToPreview(name)">Preview</span>
            </div>
        </div>
    </div>
</template>

<script>
import truncate from "@/helpers/truncateText";
export default {
    name: "TestCard",
    props: {
        tag: { default: null },
        name: { type: String, required: true },
        preview: { type: String, required: true },
        duration: { type: Number, required: false },
        recommendations: { type: Array },
    },
    computed: {
        truncatePreview() {
            return truncate(this.preview, 60);
        },
    },
    methods: {
        getThreeRec(recommendations) {
            return recommendations.slice(0, 3);
        },
        truncateRecommendation(recommendation) {
            return truncate(recommendation, 25);
        },
        navigateToPreview(jobPosition) {
            this.$router.push({
                path: "/job-position-preview",
                query: { jobPosition: jobPosition },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
le
